import { Component } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Component({
    selector: "store-products",
    templateUrl: "productSelection.component.html"
})
export class ProductSelectionComponent {

    constructor(private meta: Meta) {
    }

    changeTheme() {
        this.meta.updateTag({ name: 'theme-color', content: '#17a2b8', id: 'theme-color' }, 'id=theme-color' ); // add meta: light
    }
}
