<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
        <div class="container">
            <a class="navbar-brand" asp-area="" asp-page="/Index">Soki.By</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
                <ul class="navbar-nav">
                    <li class="nav-item" *ngIf="!authUser?.isAuthenticated">
                        <a class="nav-link text-dark" href="/Identity/Account/Register">Register</a>
                    </li>
                    <li class="nav-item" *ngIf="!authUser?.isAuthenticated">
                        <a class="nav-link text-dark" href="/Identity/Account/Login">Login</a>
                    </li>
                    <li class="nav-item" *ngIf="!!authUser?.isAuthenticated">
                        <a class="nav-link text-dark" href="/Identity/Account/Manage">{{authUser.email}}</a>
                    </li>
                    <li class="nav-item">
                        <store-cartsummary></store-cartsummary>
                    </li>
                </ul>
                <ul class="navbar-nav flex-grow-1">
                    <li class="nav-item">
                        <a class="nav-link text-dark" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" href="/Privacy">Privacy</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>