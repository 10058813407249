<app-header></app-header>

<div class="container" style="margin: 0 auto;">
    <h2 class="text-center m-2">Подтверждение заказа</h2>

    <div class="container">
        <table *ngIf="order.orderConfirmation; else nodata" class="table m-2">
            <tr><th>Заказ</th><td>{{order.orderConfirmation.orderId}}</td></tr>
            <tr><th>Стоимость</th><td>{{order.orderConfirmation.amount}}</td></tr>
            <tr><th>Платежный код</th><td>{{order.orderConfirmation.authCode}}</td></tr>
        </table>
        <div class="text-center">
            <button class="btn btn-primary m-1" routerLink="/">Готово</button>
        </div>

        <ng-template #nodata>
            <h3 class="text-center m-1">Подтверждение заказа...</h3>
        </ng-template>
    </div>
</div>
