<app-header></app-header>

<div class="container" style="margin: 0 auto;">
    <h2 class="text-center">Ваша Корзина</h2>
    <table class="table table-bordered table-striped p-1">
        <thead>
            <tr>
                <th>Количество</th><th>Продукт</th>
                <th class="text-right">Стоимость Единицы</th>
                <th class="text-right">Итоговая Стоимость</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="cart.selections.length == 0">
                <td colspan="4" class="text-xs-center">
                    Ваша корзина пуста
                </td>
            </tr>
            <tr *ngFor="let sel of cart.selections">
                <td>
                    <input type="number" class="form-control-sm"
                            style="width:5em" [(ngModel)]="sel.quantity" />
                </td>
                <td>{{sel.name}}</td>
                <td class="text-right">
                    {{sel.price | currency:"BYN ":"symbol":"2.2-2"}}
                </td>
                <td class="text-right">
                    {{(sel.quantity * sel.price) | currency:"BYN ":"symbol":"2.2-2" }}
                </td>
                <td class="text-center">
                    <button class="btn btn-sm btn-danger"
                            (click)="cart.updateQuantity(sel.productId, 0)">
                        Удалить
                    </button>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="3" class="text-right">Всего:</td>
                <td class="text-right">
                    {{cart.totalPrice | currency:"BYN ":"symbol":"2.2-2"}}
                </td>
            </tr>
        </tfoot>
    </table>
</div>
<div class="text-center">
    <button class="btn btn-outline-primary" routerLink="/store">Продолжить Покупки</button>
    <button class="btn btn-primary m-1" routerLink="/checkout"
            [disabled]="cart.selections.length == 0">
        Оформить Заказ
    </button>
</div>
