<app-header></app-header>

<div class="container" style="margin: 0 auto;">
    <h2 class="text-center mt-2">Шаг 1: Адрес Заказа</h2>

    <div class="p-2">
        <form novalidate #detailsForm="ngForm">
            <div class="form-group">
                <label>Имя</label>
                <input #name="ngModel" name="name" class="form-control"
                    [(ngModel)]="order.name" required />
                <div *ngIf="name.invalid" class="text-danger">
                    Введите ваше имя
                </div>
            </div>
            <div class="form-group">
                <label>Адрес</label>
                <input #address="ngModel" name="street" class="form-control"
                    [(ngModel)]="order.address" required />
                <div *ngIf="address.invalid" class="text-danger">
                    Введите ваш адрес
                </div>
            </div>
            <div class="text-center pt-2">
                <button type="button" class="btn btn-outline-primary m-1"
                        routerLink="/cart">Назад</button>
                <button type="button" class="btn btn-danger m-1" 
                        [disabled]="detailsForm.invalid"
                        routerLink="/checkout/step2">
                    Далее
                </button>
            </div>
        </form>
    </div>
</div>
