<app-header></app-header>

<div class="container" style="margin: 0 auto;">
    <h2 class="text-center m-1">Описание Заказа</h2>

    <div class="container">
        <table class="table m-2">
            <tr><th>Имя</th><td>{{order.name}}</td></tr>
            <tr><th>Адрес</th><td>{{order.address}}</td></tr>
            <tr><th>Продукты</th><td>{{order.cart.itemCount}}</td></tr>
            <tr>
                <th>Итого</th>
                <td>{{order.cart.totalPrice | currency:"BYN ":"symbol" }}</td>
            </tr>
        </table>
        <div class="text-center pt-2">
            <button type="button" class="btn btn-outline-primary m-1"
                    routerLink="/checkout/step2">
                Назад
            </button>
            <button type="button" class="btn btn-danger m-1" (click)="submitOrder()">
                Разместить Заказ
            </button>
        </div>
    </div>
</div>
