<app-header></app-header>

<div class="container" style="margin: 0 auto;">
    <h2 class="text-center mt-1">Шаг 2: Платёж</h2>

    <div class="p-2">
        <form novalidate #paymentForm="ngForm">
            <div class="form-group">
                <label>Номер карты</label>
                <input #cardNumber="ngModel" name="cardNumber" class="form-control"
                    [(ngModel)]="order.payment.cardNumber" required />
                <div *ngIf="cardNumber.invalid" class="text-danger">
                    Введите номер карты
                </div>
            </div>
            <div class="form-group">
                <label>Срок действия карты</label>
                <input #cardExpiry="ngModel" name="cardExpiry" class="form-control"
                    [(ngModel)]="order.payment.cardExpiry" required />
                <div *ngIf="cardExpiry.invalid" class="text-danger">
                    Введите срок действия карты
                </div>
            </div>
            <div class="form-group">
                <label>Код карты</label>
                <input #cardCode="ngModel" name="cardCode" class="form-control"
                    [(ngModel)]="order.payment.cardSecurityCode" required />
                <div *ngIf="cardCode.invalid" class="text-danger">
                    Введите код карты
                </div>
            </div>
            <div class="text-center pt-2">
                <button type="button" class="btn btn-outline-primary m-1"
                        routerLink="/checkout/step1">
                    Назад
                </button>
                <button type="button" class="btn btn-danger m-1" 
                        [disabled]="paymentForm.invalid"
                        routerLink="/checkout/step3">Далее</button>
            </div>
        </form>
    </div>
</div>