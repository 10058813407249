<div class="m-1">
    <button class="btn btn-outline-primary btn-block"
    [class.active]="service.currentCategory == ''"
            (click)="service.currentCategory = ''">
        Все Категории
    </button>
    <!-- service.categories.reverse(): to show 500мл ашкые and then 1л-->
    <button *ngFor="let category of service.categories.reverse()"
            class="btn btn-outline-primary btn-block"
            [class.active]="service.currentCategory.toLowerCase()
                == category.toLowerCase()"
            (click)="changeCategory(category)">
        {{category}}
    </button>
    <a class="btn btn-outline-primary btn-block active" routerLink="/cart">
        Ваша Корзина
    </a>
    <a *ngIf="!!repo?.authUser?.isAuthenticated && !!repo?.authUser?.isAdmin" class="btn btn-block btn-secondary" routerLink="/admin"><!-- (click)="changeTheme()" -->
        Administration
    </a>
</div>
