import { Component } from "@angular/core";
import { Repository } from "../models/repository";
import { AuthUser } from "../models/auth-user";

@Component({
    selector: "app-header",
    templateUrl: "app-header.component.html"
})
export class AppHeaderComponent {

    authUser: AuthUser;

    constructor(private repo: Repository) {
    }

    async ngOnInit() {
       this.authUser = await this.repo.getUser(); 
    }
}
