<div class="text-right p-1" style="color:#007bff">
    <small *ngIf="itemCount > 0; else empty">
        {{ itemCount }} единиц(а), {{ totalPrice | currency:"BYN ":"symbol" }}
    </small>
    <button class="btn btn-sm ml-1"
            [disabled]="itemCount == 0"
            routerLink="/cart">
        <i class="fa fa-shopping-cart" style="color:#007bff"></i>
    </button>
</div>

<ng-template #empty>
    <small class="text-muted">
        (корзина пуста)
    </small>
</ng-template>
